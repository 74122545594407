import "./Description.css";
import roSur_video from "../assets/roSur_video.mp4";
import l2Sur_video from "../assets/l2Sur_video.mp4";
import essence_video from "../assets/essence_video.mp4";
import discord from "../assets/icon_discord.svg";
import team from "../assets/team.webp";

function Description(props) {
  return (
    <div id="description">
      <div className="descriptionContainer">
        <div></div>
        <div className="infoBackground">
          {/* Right Start */}
          <div className="rightContainer">
            <div>
              <div className="title">
                <h2>¿Que tipo de jugadores somos?</h2>
                <p>
                  <strong>SouthGames</strong> es una innovadora empresa de
                  videojuegos que se especializa en revivir y modernizar la
                  experiencia de los juegos MMORPG&nbsp;
                  <strong>
                    Massively Multiplayer Online Role-Playing Games
                  </strong>
                  &nbsp; clásicos de los años 2000. Fundada por un equipo de
                  apasionados desarrolladores y gamers nostálgicos, SouthGames
                  tiene la misión de traer de vuelta esos mundos virtuales
                  inolvidables que marcaron una era, proporcionando servidores
                  estables, actualizaciones regulares y una comunidad activa y
                  acogedora.
                </p>
              </div>
            </div>
            <div className="trim">
              <img src={team} className="teamImage" />
            </div>
          </div>
        </div>
        <div className="roSurBackground">
          {/* Right Start */}
          <div className="rightContainer">
            <div className="videoContainer">
              <video autoplay="autoplay" loop muted>
                <source src={roSur_video} type="video/mp4" />
              </video>
            </div>
            <div>
              <div className="title">
                <h2>MMORPG RO Sur</h2>
              </div>
              <p>
                Sumérgete en el vibrante mundo de Ragnarok Online, un MMORPG que
                captura la esencia de la aventura y la camaradería como ningún
                otro. Desde el momento en que eliges tu clase, desde valientes
                Swordsman hasta místicos Magicians, cada paso te lleva por un
                camino lleno de emocionantes desafíos y descubrimientos. Con su
                rica y colorida estética inspirada en la mitología nórdica,
                Ragnarok no solo es un juego, sino una puerta a un universo
                donde cada esquina esconde maravillas y cada encuentro es una
                historia por contar.
              </p>
              <div className="buttons">
                <button>
                  <a href="https://discord.com/invite/vvR449RCUa">
                    <img src={discord} alt="" />
                    <p>Discord</p>
                  </a>
                </button>
                <button>
                  <a href="https://rosur.cl/">
                    <span class="material-symbols-rounded">open_in_new</span>
                    <p>Visitar Sitio Web</p>
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className="colorBackground"></div>
        </div>
        <div className="l2SurBackground">
          {/* Left Start */}
          <div className="leftContainer">
            <div>
              <div className="title">
                <h2>MMORPG L2 Sur High Five</h2>
              </div>
              <p>
                Adéntrate en el épico mundo de Lineage 2, un MMORPG que redefine
                la experiencia de juego con su inmersiva narrativa, gráficos
                impresionantes y un sistema de combate dinámico que te mantiene
                al filo de tu asiento. Desde el vasto continente de Aden hasta
                las tierras desconocidas que esperan ser exploradas, Lineage 2
                ofrece una aventura sin igual donde cada decisión y batalla
                forja tu destino. Al iniciar tu viaje, eliges entre razas
                legendarias, desde los nobles Elfos hasta los enigmáticos Elfos
                Oscuros, cada una con habilidades únicas que definen tu camino.
                La personalización profunda de personajes te permite no solo
                diseñar un héroe a tu medida, sino también especializarlo en
                clases que cambian el curso de la batalla, desde poderosos
                guerreros hasta hechiceros maestros de la magia.
              </p>
              <div className="buttons">
                <button>
                  <a href="https://discord.com/invite/97zuBATcPH">
                    <img src={discord} alt="" />
                    <p>Discord</p>
                  </a>
                </button>
                <button>
                  <a href="https://l2sur.cl/">
                    <span class="material-symbols-rounded">open_in_new</span>
                    <p>Visitar Sitio Web</p>
                  </a>
                </button>
              </div>
            </div>
            <div className="videoContainer">
              <video autoplay="autoplay" loop muted>
                <source src={essence_video} type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="colorBackground"></div>
        </div>
        <div className="essenceBackground">
          {/* Right Start */}
          <div className="rightContainer">
            <div className="videoContainer">
              <video autoplay="autoplay" loop muted>
                <source src={l2Sur_video} type="video/mp4" />
              </video>
            </div>
            <div>
              <div className="title">
                <h2>MMORPG L2 Sur Essence</h2>
              </div>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil
                omnis quibusdam corrupti dolore. Rerum, quam. Possimus, quaerat.
                Laborum provident id laboriosam eos cupiditate ea tenetur
                distinctio neque praesentium nam? Dolor, ad corrupti dicta quod
                vitae ipsa. Distinctio expedita maxime magnam, sed amet
                consequatur rerum, quod voluptatibus optio doloribus cumque
                nihil blanditiis unde totam necessitatibus porro ipsa qui!
                Distinctio officia labore laboriosam deleniti deserunt, maxime
                illum cum! Alias a aperiam iusto exercitationem facilis odit
                assumenda doloribus ipsam aliquid sit ullam, cumque totam non
                aliquam voluptatum commodi accusantium. Placeat, beatae
                voluptatibus? Aliquam optio laboriosam unde neque laudantium.
                Neque impedit similique totam provident!
              </p>
              <div className="buttons">
                <button>
                  <a href="https://discord.com/invite/gYqpnm6Q">
                    <img src={discord} alt="" />
                    <p>Discord</p>
                  </a>
                </button>
                <button>
                  <a href="https://essence.l2sur.cl/">
                    <span class="material-symbols-rounded">open_in_new</span>
                    <p>Visitar Sitio Web</p>
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className="colorBackground"></div>
        </div>
      </div>
    </div>
  );
}

export default Description;
