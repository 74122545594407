export const languageJson = {
  "missing-email": "Email no ingresado.",
  "missing-password": "Contraseña no ingresada.",
  "weak-password": "La contraseña debe contener al menos 6 caracteres.",
  "invalid-email": "Email invalido.",
  "email-already-in-use": "Este correo electrónico ya está en uso.",
  "user-not-found": "Usuario no encontrado.",
  "wrong-password": "Contraseña incorrecta.",
  "too-many-requests": "Demasiados intentos, inténtelo más tarde.",
};
