import { Outlet } from "react-router-dom";
// import MainNavigation from "../components/MainNavigation";
import Auth from "./components/Auth";
import Nav from "./components/LoginButton";
export default function RootLayout() {
  return (
    <div className="rootContainer">
      {/* <h1>Root Layout</h1> */}
      <Outlet />
      {/* <Auth /> */}
      {/* <Nav /> */}
      {/* Z-Index priority depending on element position */}
    </div>
  );
}
