import "./LoginButton.css";
import { Link } from "react-router-dom";

export default function Nav() {
  return (
    <div>
      <Link to="/login" className="link loginButton">
        Iniciar sesión
      </Link>
    </div>
  );
}
