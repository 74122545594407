import "./App.css";
import Footer from "./components/Footer";
import Description from "./components/Description";
import Projects from "./components/Projects";
import Logo from "./components/Logo";
import RootLayout from "./Root.js";
import Auth from "./components/Auth";
import Login from "./components/LoginButton.jsx";
import HomeButton from "./components/HomeButton.jsx";
// Parent-Child Object Based Routing
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "/",
        element: (
          <div className="appContainer">
            <Logo />
            <Description />
            <Projects />
            <Footer />
            <Login />
          </div>
        ),
      },
      {
        path: "/login",
        element: (
          <>
            <Auth />
            <HomeButton />
          </>
        ),
      },
    ],
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
