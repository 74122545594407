import "./Auth.css";
import google from "../assets/icon_google.svg";
import { useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithRedirect,
} from "firebase/auth";
import { auth } from "../index.js";
import { languageJson } from "../language.js";
import l2Sur_video from "../assets/l2Sur_video.mp4";

function Auth() {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  // Auth State Changed
  const [user, setUser] = useState({});

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        document.getElementById("userLogged").style =
          "display: flex !important;";
        document.getElementById("logOutButton").style =
          "display: inline !important;";
      }
    });
  }, []);

  const register = async () => {
    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
    } catch (error) {
      switch (error.message) {
        case "Firebase: Error (auth/missing-email).":
          document.getElementById("loginEmailErrorMessage").innerText =
            languageJson["missing-email"];
          break;
        case "Firebase: Error (auth/missing-password).":
          document.getElementById("loginPasswordErrorMessage").innerText =
            languageJson["missing-password"];
          break;
        case "Firebase: Password should be at least 6 characters (auth/weak-password).":
          document.getElementById("loginPasswordErrorMessage").innerText =
            languageJson["weak-password"];
          break;
        case "Firebase: Error (auth/invalid-email).":
          document.getElementById("loginEmailErrorMessage").innerText =
            languageJson["invalid-email"];
          break;
        case "Firebase: Error (auth/email-already-in-use).":
          document.getElementById("loginEmailErrorMessage").innerText =
            languageJson["email-already-in-use"];
          break;
      }
    }
  };

  const login = async () => {
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
    } catch (error) {
      switch (error.message) {
        case "Firebase: Error (auth/missing-email).":
          document.getElementById("loginEmailErrorMessage").innerText =
            languageJson["missing-email"];
          break;
        case "Firebase: Error (auth/missing-password).":
          document.getElementById("loginPasswordErrorMessage").innerText =
            languageJson["missing-password"];
          break;
        case "Firebase: Error (auth/invalid-email).":
          document.getElementById("loginEmailErrorMessage").innerText =
            languageJson["invalid-email"];
          break;
        case "Firebase: Error (auth/user-not-found).":
          document.getElementById("loginPasswordErrorMessage").innerText =
            languageJson["user-not-found"];
          break;
        case "Firebase: Error (auth/wrong-password).":
          document.getElementById("loginPasswordErrorMessage").innerText =
            languageJson["wrong-password"];
          break;
        case "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).":
          document.getElementById("loginPasswordErrorMessage").innerText =
            languageJson["too-many-requests"];
          break;
      }
    }
  };

  const logout = async () => {
    await signOut(auth);
    document.getElementById("userLogged").style = "display: none !important;";
    document.getElementById("logOutButton").style = "display: none !important;";
  };

  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      return signInWithRedirect(auth, provider);
    } catch (error) {}
  };

  return (
    <div id="auth" className="background">
      <div className="videoContainer">
        <video autoplay="autoplay" loop muted>
          <source src={l2Sur_video} type="video/mp4" />
        </video>
      </div>
      <div className="authContainer">
        {/* Log In Title */}
        <div className="mainTitle">
          <h2>¡Ingresa con tu cuenta aquí!</h2>
        </div>
        {/* Form */}
        <div className="form">
          {/* Log In */}
          <div className="logIn">
            <div>
              <span class="material-symbols-rounded">mail</span>
              <input
                type="text"
                name=""
                id="loginEmailInput"
                placeholder="Email"
                onChange={(event) => {
                  setLoginEmail(event.target.value);
                }}
              />
            </div>

            <p id="loginEmailErrorMessage" className="errorMessage"></p>

            <div>
              <span class="material-symbols-rounded">key</span>
              <input
                type="password"
                name=""
                id="loginPasswordInput"
                placeholder="Contraseña"
                onChange={(event) => {
                  setLoginPassword(event.target.value);
                  if (event.target.value.length >= 6) {
                    document.getElementById("loginPasswordInput").className =
                      "validInput";
                  } else {
                    document.getElementById("loginPasswordInput").className =
                      "invalidInput";
                  }
                }}
              />
            </div>

            <p id="loginPasswordErrorMessage" className="errorMessage"></p>

            <button
              onClick={() => {
                login();
                document.getElementById("loginEmailErrorMessage").innerText =
                  "";
                document.getElementById("loginPasswordErrorMessage").innerText =
                  "";
                document.getElementById("loginPasswordInput").value = "";
                document.getElementById("loginPasswordInput").className =
                  "invalidInput";
              }}
            >
              <p>Iniciar sesión</p>
            </button>
          </div>

          {/* Register Invitation */}
          <div className="invitation">
            <div></div>
            <p>¿No tienes una cuenta?</p>
            <div></div>
          </div>

          <button
            onClick={() => {
              register();
              document.getElementById("loginEmailErrorMessage").innerText = "";
              document.getElementById("loginPasswordErrorMessage").innerText =
                "";
              document.getElementById("loginPasswordInput").value = "";
              document.getElementById("loginPasswordInput").className =
                "invalidInput";
            }}
          >
            <p>Registrarse</p>
          </button>

          {/* Google Register */}
          <button onClick={signInWithGoogle} className="googleButton">
            <img src={google} alt="" />
            <p>Continuar con Google</p>
          </button>

          <div id="userLogged">
            <div></div>
            <p>
              Nombre de usuario:{" "}
              <small>{user ? user.email : "Sesión no iniciada"}</small>
            </p>
            <div></div>
          </div>

          <button id="logOutButton" onClick={logout}>
            <p>Cerrar sesión</p>
          </button>
        </div>
      </div>
      <div className="colorBackground"></div>
    </div>
  );
}

export default Auth;
