import "./Footer.css";
import logo from "../assets/southgames_logo_rounded.svg";
import icon_facebook from "../assets/icon_facebook.png";
import icon_instagram from "../assets/icon_instagram.png";
import icon_linkedin from "../assets/icon_linkedin.png";
import icon_whatsapp from "../assets/icon_whatsapp.png";

function Footer() {
  return (
    <div id="footer" className="background">
      <div className="footerContainer container">
        {/* <div className="mainTitle">
          <small>small footer description</small>
          <h1>Footer</h1>
        </div> */}
        {/* Main Section Start */}
        <div className="contact">
          <div className="direction">
            <div>
              <span class="material-symbols-rounded">location_on</span>
              <h3>Nuestra Dirección</h3>
            </div>
            <p>1111B S Governors Ave STE 3262, Dover, USA.</p>
          </div>
          <div className="phone">
            <div>
              <span class="material-symbols-rounded">call</span>
              <h3>Nuesto Teléfono</h3>
            </div>
            <a href="tel:+13022447943">+1 (302) 244 7943</a>
            {/* <p>(302) 2447943</p> */}
          </div>
          <div className="email">
            <div>
              <span class="material-symbols-rounded">mail</span>
              <h3>Envíanos un Email</h3>
            </div>
            {/* <p>play@southgames.cl</p> */}
            <a href="mailto:play@southgames.cl">play@southgames.cl</a>
          </div>
        </div>
        {/* Main Section End */}
        <hr />
        {/* Bottom Section Start */}
        <div className="bottom">
          {/* Social */}
          <div className="social">
            <img src={logo} alt="" className="logo" draggable="false" />
            <h2>SouthGames, LLC</h2>
            {/* <div className="icons">
              <a href="#">
                <img src={icon_facebook} alt="" />
              </a>
              <a href="#">
                <img src={icon_instagram} alt="" />
              </a>
              <a href="#">
                <img src={icon_linkedin} alt="" />
              </a>
              <a href="#">
                <img src={icon_whatsapp} alt="" />
              </a>
            </div> */}
          </div>
          {/* Politics */}
          <small></small>
          <div className="politics">
            <small>
              © Copyright 2024 Southgames, LLC. All Rights Reserved.
            </small>
            {/* <a href="">Terms & Conditions</a>
            <a href="">Privacy Policy</a> */}
          </div>
        </div>
        {/* Bottom Section End */}
      </div>
      <div className="colorBackground"></div>
    </div>
  );
}

export default Footer;
