// import React from "react";
import "./Logo.css";
import logo from "../assets/logo_southgames.svg";

function Logo() {
  return (
    <div id="logo" className="background">
      <div className="logoContainer container">
        <img src={logo} alt="" />
      </div>
      <div className="colorBackground"></div>
    </div>
  );
}

export default Logo;
