import "./Projects.css";
import tokens from "../assets/tokens.png";
import tokenBig from "../assets/tokensBig.webp";
import tokenMedium from "../assets/tokensMedium.webp";
import tokenSmall from "../assets/tokensSmall.webp";

function Projects() {
  return (
    <div id="projects" className="background">
      <div className="projectsContainer container">
        {/* Top Start */}
        <div className="mainTitle">
          <h1>Chauchas</h1>
          <small>
            La Chaucha es la moneda oficial de la comunidad de juegos SouthGames
            y solo es obtenible por donación, por votación, intercambio con
            otros jugadores o por eventos. Puede ser utilizada para comprar
            items cosméticos, runas de experiencia, habilidades especiales y
            más.
          </small>
          {/* <hr /> */}
        </div>
        {/* Top End */}
        <div className="cards">
          <div>
            <span class="material-symbols-rounded">sell</span>
            <h2>¡Amo las Chauchas!</h2>
            <p>¡Muchas gracias!</p>
            <h1>
              3000{" "}
              <small>
                <sub>/chauchas</sub>
              </small>
            </h1>
            <div className="radialBackground">
              <div></div>
              <img src={tokenSmall} alt="" />
            </div>
            <button>
              <a
                href="https://donate.stripe.com/14kg18gvq6S5cgg5km"
                target="_blank"
              >
                <span class="material-symbols-rounded">open_in_new</span>
                <p>5.90 USD</p>
              </a>
            </button>
          </div>
          <div>
            <span class="material-symbols-rounded">sell</span>
            <h2>¡Vivan las Chauchas!</h2>
            <p>Woo... ¡Muchísimas gracias!</p>
            <h1>
              7000{" "}
              <small>
                <sub>/chauchas</sub>
              </small>
            </h1>
            <div className="radialBackground">
              <div></div>
              <img src={tokenMedium} alt="" />
            </div>
            <button>
              <a
                href="https://donate.stripe.com/fZe3emfrm1xL0xy8wx"
                target="_blank"
              >
                <span class="material-symbols-rounded">open_in_new</span>
                <p>9.90 USD</p>
              </a>
            </button>
          </div>
          <div>
            <span class="material-symbols-rounded">sell</span>
            <h2>¡Pásame esas chauchas!</h2>
            <p>¿Dónde te prendo la vela?</p>
            <h1>
              15000{" "}
              <small>
                <sub>/chauchas</sub>
              </small>
            </h1>
            <div className="radialBackground">
              <div></div>
              <img src={tokenBig} alt="" />
            </div>
            <button>
              <a
                href="https://donate.stripe.com/6oE9CK6UQ4JXbcc6oo"
                target="_blank"
              >
                <span class="material-symbols-rounded">open_in_new</span>
                <p>19.90 USD</p>
              </a>
            </button>
          </div>
        </div>
      </div>
      <div className="colorBackground"></div>
    </div>
  );
}

export default Projects;
